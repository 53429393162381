<script setup>
    import { watch, reactive, inject, computed, ref } from "vue";
    import VueRouter from "vue-router";
    import { userSession } from "o365-modules";
    import { InjectionKeys } from 'o365-utils';

    import HamburgerMenu from 'o365.pwa.vue.components.navbar.HamburgerMenu.vue';
    import DeveloperMenu from 'o365.pwa.vue.components.navbar.DeveloperMenu.vue';

    const { pwaStoreKey } = InjectionKeys;

    const { state: offlineState } = inject(pwaStoreKey);

    const { isDeveloper } = userSession;

    const router = VueRouter.useRouter();
    const route = VueRouter.useRoute();

    const data = reactive({});

    const navbarColor = ref(window.__navBarData.toolbarColor);

    const hasDatabaseConnection = computed(() => {
        return offlineState?.pwaState?.hasDatabaseConnection ?? false;
    });

    const databaseConnectionTitle = computed(() => {
        return hasDatabaseConnection.value ? $t('You are connected to the database') : $t('You are not connected to the database');
    });
    
    function getRouteText(path) {
        const route = router.options.routes.filter(e => e.path === path)[0];

        if (route) {
            return route.meta.title;
        }

        return "Back";
    }

    watch(route, () => {
        const state = router.options.history.state;

        if (state.current === "/") {
            data.text = null;
            data.func = null;
        } else if (state.back != null) {
            // there is history, so we just go back
            data.text = getRouteText(state.back);
            data.func = () => router.back();
        } else {
            // there is no history, but we are not at root, go one step up
            const newRoute = ("/" + state.current.split("/").slice(0, -1).join("/")).replace("//", "/");

            data.text = getRouteText(newRoute);
            data.func = () => router.replace(newRoute);
        }
    }, { immediate: true });
</script>

<template>
    <div class="d-flex justify-content-between text-white flex-shrink-0" style="height:48px!important;" :style="{ 'background-color': navbarColor }">
        <div class="d-flex d-flex justify-content-center align-items-center gap-1">
            <HamburgerMenu></HamburgerMenu>

            <a href="/offline/offline-home" class="text-white">
                <i class="bi bi-house-fill"></i>
            </a>

            <!-- <button class="d-flex align-items-center btn btn-link" -->
                <!-- style="padding-left: 0.75rem; height: 100%; color: white; text-decoration: none; font-size: 0.9em; white-space: nowrap; gap: 0.25rem;" -->
                <!-- @click="data?.func()" -->
                <!-- v-if="data.func"> -->
                <!-- <i class="bi bi-chevron-left"></i> -->
                <!-- <span>{{ data.text }}</span> -->
            <!-- </button> -->
        </div>

        <div class="d-flex justify-content-center align-items-center">
            <div :key="$route.meta.title">
                <span style="font-size: 1.1em; font-weight: 500;">{{ $route.meta.title }}</span>
            </div>
        </div>

        <div class="d-flex d-flex justify-content-center align-items-center gap-1">
            <i class="position-relative small bi bi-server" :class="{ 'me-3': !isDeveloper }" :title="databaseConnectionTitle">
                <span class="p-1 top-0 border start-100 border-light rounded-circle translate-middle position-absolute" :class="hasDatabaseConnection ? 'bg-success' : 'bg-danger'"></span>
            </i>

            <DeveloperMenu v-if="isDeveloper"></DeveloperMenu>
        </div>
    </div>
</template>

<style scoped>
    a.no-style {
        text-decoration: none;
    }

    a.no-style:hover, a.no-style:focus, a.no-style:active, a.no-style:visited {
        text-decoration: none;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s, scale 0.2s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0;
        scale: 0.95;
    }
</style>
